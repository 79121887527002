<script>
import appConfig from '@src/app.config'
import Layout from '@layouts/main'
import axios from '@/axios'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import Notification from '@components/modal/notification'

/**
 * Contents component
 */

export default {
  page: {
    title: 'Notification',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  components: {
    Layout,
    vueDropzone: vue2Dropzone,
    Loading,
    Notification,
  },
  data() {
    return {
      isLoading: false,
      targetData: undefined,
      targetAll: true,
      select: '',
      type: undefined,
      contentType: undefined,
      courseId: undefined,
      subId: undefined,
      form: {
        title: '',
        text: '',
        image: '',
      },
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailWidth: 340,
        maxFilesize: 100,
        maxFiles: 1,
        addRemoveLinks: true,
        acceptedFiles: '.jpeg,.jpg,.png,.gif',
        dictDefaultMessage: "<i class='fa fa-cloud-upload'></i> UPLOAD ME",
      },
      optionsTarget: [
        { value: true, text: 'All User' },
        { value: false, text: 'Only Member Or purchase' },
      ],
    }
  },

  mounted() {},
  methods: {
    handleChangTarget(e) {
      this.targetAll = e
    },
    handleClickReset() {
      this.select = ''
      this.targetData = undefined
      this.form = {
        title: '',
        text: '',
        image: '',
      }
    },

    async addText(e) {
      // e.content_title ? (this.targetData = e) : (this.targetData = undefined)
      if (e.sub_name) {
        this.select = e.category_name + '-' + e.sub_name
      } else if (e.category_name) {
        this.select = e.category_name
      } else if (e.content_title) {
        this.type = 'content'
        this.contentType = e.type
        this.select = 'content' + '-' + e.type + '-' + e.content_title
        this.targetData = e
        // this.courseId = e._id
      }
    },
    onLoading() {
      this.isLoading = true
      // simulate AJAX
    },
    onCancel() {
      this.isLoading = false

      // console.log('User cancelled the loader.')
    },
    vfileAdded(file) {
      this.form.image = file
      this.ismanuallyadd = false
    },

    async handleClickSend() {
      //   if (!this.targetData) {
      //     this.$toast.error('Please select content')
      //     return
      //   }

      if (!this.form.title || !this.form.text) {
        this.$toast.error('Please enter all required fields')
        return
      }

      let ConFirm = confirm('Confirm')
      if (!ConFirm) return
      this.onLoading()

      await axios
        .post(`/api/notification/push`, {
          title: this.form.title,
          text: this.form.text,
          contentData: this.targetData,
          targetAll: this.targetAll,
          type: this.type,
          contentType: this.contentType,
          courseId: this.courseId,
          subId: this.subId,
        })
        .then((result) => {
          this.onCancel()
          this.$toast.success('successfully')
        })
        .catch((err) => {
          this.onCancel()
          this.$toast.err(err)
        })

      this.handleClickReset()
    },
  },
}
</script>
<template>
  <Layout>
    <div class="row page-title align-items-center">
      <div class="col-md-3 col-xl-6">
        <h4 class="mb-1 mt-0">Notification</h4>
        <h5 class="mb-1 mt-0">Select: {{ select }}</h5>
      </div>
      <div class="col-xl-6">
        <button
          class="btn btn-primary rounded-pill width-lg float-right aligncenter ml-2 mr-2"
          @click="$refs.notiModal.openModal()"
        >
          Select
        </button>
      </div>
      <div class="row mt-2" style="height:100%; width:100%;">
        <div class="col-12">
          <div class="card">
            <div class="card-body" style="height:100%;">
              <b-form class="form-horizontal" @submit.prevent="">
                <div class="row center">
                  <div class="col-7">
                    <b-form-group label-cols-lg="0">
                      <label>Title</label>
                      <b-form-input
                        id="title"
                        placeholder="Enter title..."
                        name="title"
                        type="text"
                        v-model="form.title"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group label-cols-lg="0">
                      <label>Message</label>

                      <b-form-textarea
                        id="textarea"
                        placeholder="Enter something..."
                        rows="3"
                        max-rows="6"
                        v-model="form.text"
                      ></b-form-textarea>
                    </b-form-group>

                    <!-- <b-form-group label-cols-lg="0">
                      <label>Target User</label>

                      <b-form-select
                        v-model="targetAll"
                        :options="optionsTarget"
                      ></b-form-select>
                    </b-form-group> -->

                    <!-- <b-form-group label-cols-lg="0" v-if="!form.is_video">
                      <label>
                        Image
                        <small class="color-text-small"
                          >Width 354 x Height 338. Just only one.</small
                        >
                      </label>

                      <vue-dropzone
                        @vdropzone-file-added="vfileAdded"
                        acceptedFiles="image/*"
                        ref="myVueDropzone"
                        :options="dropzoneOptions"
                      ></vue-dropzone>
                    </b-form-group> -->

                    <loading
                      :active.sync="isLoading"
                      :can-cancel="false"
                      :on-cancel="onCancel"
                      :is-full-page="true"
                    ></loading>

                    <b-form-group>
                      <button
                        @click="handleClickSend"
                        class="btn btn-primary float-right ml-2"
                        >Send</button
                      >
                      <button
                        @click="handleClickReset"
                        type="submit"
                        class="btn btn-danger float-right "
                        >Reset</button
                      >
                    </b-form-group>
                  </div>
                </div>
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Notification @send-data="addText($event)" ref="notiModal"></Notification>
  </Layout>
</template>
