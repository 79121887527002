var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"centerModal",staticClass:"modal fade",attrs:{"id":"bs-example-modal-xl","tabindex":"-1","role":"dialog","aria-labelledby":"myExtraLargeModalLabel","aria-hidden":"true"}},[_c('div',{staticClass:"modal-dialog modal-xl"},[_c('div',{staticClass:"modal-content"},[_vm._m(0),_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"d-flex"},[_c('b-tabs',{attrs:{"content-class":"mt-3"}},[_c('b-tab',{attrs:{"title":"Content","active":_vm.active},on:{"click":() => {
                  ;(_vm.tabIndex = 0), (_vm.active = true), _vm.loadData()
                }}})],1),(_vm.active)?_c('div',[_c('b-form-select',{staticStyle:{"margin-left":"10px"},attrs:{"options":_vm.options},on:{"change":function($event){return _vm.loadData()}},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1):_vm._e(),_c('Search',{staticClass:"search-padding",attrs:{"types":_vm.search_types},on:{"onSearch":function($event){return _vm.onSearch($event)}}})],1),_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-striped mb-0"},[_vm._m(1),_c('tbody',_vm._l((_vm.data.data),function(x,idx){return _c('tr',{key:x.id,staticClass:"pointer",class:{ 'table-selected': x.checked },on:{"click":function($event){return _vm.holdData(x, idx)},"dblclick":_vm.selectData}},[_c('th',{attrs:{"scope":"row"}},[_vm._v(_vm._s(idx + 1))]),_c('td',[_c('img',{staticClass:"img-fluid",staticStyle:{"max-width":"170px","height":"120px"},attrs:{"src":`${_vm.baseImageUrl}/${x.category_image ||
                        x.img_url ||
                        x.sub_image}`,"alt":""}}),(!x.sub_image && _vm.active && x.video_data)?_c('iframe',{attrs:{"src":`https://player.vimeo.com/video/${x.video_data.vimeoid}`,"width":"250","height":"150","frameborder":"0","allow":"  fullscreen","allowfullscreen":""}}):_vm._e()]),_c('td',{staticStyle:{"width":"50%"}},[_vm._v(_vm._s(x.category_name || x.content_title || x.sub_name))])])}),0)])])]),_c('div',{staticClass:"modal-footer"},[_c('Pagination',{directives:[{name:"show",rawName:"v-show",value:(_vm.data.total > 1),expression:"data.total > 1"}],staticClass:"pull-left",attrs:{"data":_vm.data,"page":_vm.page},on:{"onPage":function($event){return _vm.onPage($event)}}}),_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":_vm.selectData}},[_vm._v("Select")])],1)])]),_c('loading',{attrs:{"active":_vm.isLoading,"can-cancel":false,"on-cancel":_vm.onCancel,"is-full-page":true},on:{"update:active":function($event){_vm.isLoading=$event}}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-header"},[_c('h5',{staticClass:"modal-title",attrs:{"id":"myExtraLargeModalLabel"}}),_c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{staticClass:"tf-2",attrs:{"scope":"col"}},[_vm._v("#")]),_c('th',{staticClass:"tf-2",attrs:{"scope":"col"}}),_c('th',{staticClass:"tf-3-5",attrs:{"scope":"col"}},[_vm._v("Title")])])])
}]

export { render, staticRenderFns }